.calculator-container {
  width: 300px;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-container {
  margin-bottom: 10px;
}

input {
  width: 96%;
  height: 40px;
  font-size: 16px;
  padding: 5px;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.calculator-button {
  width: 70px;
  height: 40px;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
}

.clear-button {
  background-color: #ff5555;
  color: #fff;
}

.equals-button {
  background-color: #4caf50;
  color: #fff;
}

.result {
  margin-top: 10px;
  font-size: 18px;
}
