/* Navbar.css */

.navbar {
  position: fixed;
  width: 98%;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar h1 {
  margin: 0;
  color: white;
  text-decoration: none;
}

.navbar ul {
  display: flex;
  list-style-type: none;
  margin: 0;
}

.navbar li {
  margin-right: 1rem;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.navbar .subscribe-link {
  background: #2196F3;
  border: none; /* Remove border */
  padding: 0.5rem 1rem; /* Adjust padding */
  color: white; /* Text color */
  cursor: pointer; /* Change cursor on hover */
  border-radius: 5px; /* Add border-radius */
}

.navbar .subscribe-link:hover {
  background: #0d8cec; /* Change background color on hover */
}
