.footer {
  background-color: #333;
  color: white;
  padding: 2rem 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer h2 {
  margin-bottom: 1rem;
  flex-basis: 30%;
}

.footer p {
  margin: 0.5rem 0;
  flex-basis: 30%;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-basis: 30%;
}

.footer a {
  color: white;
  text-decoration: none;
  margin: 0.5rem;
}

.footer form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
}

.footer input {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
}

.footer button {
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer h2, .footer p, .footer ul {
    flex-basis: 100%;
  }
}
