/* App.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
}

#root {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.app-container {
  display: flex;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
}

.IPOCalculator,
.IPOWebsite {
  flex: 0.5;
  margin-top: 5%;
  margin-bottom: 5%;
}

.Footer {
  margin-top: auto;
  display: flex;
}